// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notification-dialog__container___xybZ_>div>div>div:nth-child(2){font-weight:bold}.notification-dialog__container___xybZ_>div>div>div:nth-child(3){line-height:20px}.notification-dialog__container___xybZ_>div>div>div:nth-child(4)>div>button{font-weight:bold}", "",{"version":3,"sources":["/codebuild/output/src2537419695/src/src/components/core/styles/notification-dialog.scss"],"names":[],"mappings":"AAIQ,iEACI,gBAAA,CAIJ,iEACI,gBAAA,CAKA,4EACI,gBAAA","file":"notification-dialog.scss","sourcesContent":[".container {\n    >div>div {\n\n        // Title\n        >div:nth-child(2) {\n            font-weight: bold;\n        }\n\n        // Message\n        >div:nth-child(3) {\n            line-height: 20px;\n        }\n\n        // Actions\n        >div:nth-child(4){\n            >div > button{\n                font-weight: bold;\n            }\n        }\n    }\n}"]}]);
// Exports
exports.locals = {
	"container": "notification-dialog__container___xybZ_"
};
module.exports = exports;
