import { AdvancedLoadItem } from 'interfaces/advanced-load-item';
import * as AdvancedLoadsConstants from '../../constants/advancedLoadsConstants';
import { LoadStatus } from '../../enums/load-status';
import { AdvancedLoadsSearchFilter } from '../../interfaces/advanced-loads-search-filter';
import { PaginationData } from '../../interfaces/pagination-data';

const initialState = {
  displayedLoads: [] as AdvancedLoadItem[],
  pagination: {} as PaginationData,
  summary: {
    amount: 0,
    payment: 0,
  },
  selectedLoads: [] as AdvancedLoadItem[],
  searchFilters: {
    statusFilters: [LoadStatus.All],
    sortBy: 'Date',
    sortAscending: false,
    startDate: null,
    endDate: null,
    filterText: '',
    pageSize: 25,
    pageNumber: 1,
  } as AdvancedLoadsSearchFilter,
  displayLoadingIndicator: true,
};

export default function advancedLoadsReducer(state = initialState, action: { type: string; payload: any }) {
  let newState = state;
  switch (action.type) {
    case AdvancedLoadsConstants.SET_PAGINATION:
      return { ...newState, pagination: action.payload };
    case AdvancedLoadsConstants.SET_DISPLAYED_LOADS:
      return { ...newState, displayedLoads: action.payload };
    case AdvancedLoadsConstants.SET_LOAD_ITEM_CHECKED: {
      const { loadId, isChecked } = action.payload;
      const displayedLoads = newState.displayedLoads.map(load => {
        if (load.id === loadId) {
          return { ...load, isChecked };
        }
        return load;
      });
      let selectedLoads;
      if (isChecked) {
        selectedLoads = [...newState.selectedLoads, newState.displayedLoads.find(load => load.id === loadId)];
      } else {
        selectedLoads = newState.selectedLoads.filter(load => load.id !== loadId);
      }

      return { ...newState, displayedLoads, selectedLoads };
    }
    case AdvancedLoadsConstants.CHECK_ALL_LOAD_ITEMS: {
      const isChecked = action.payload;
      const displayedLoads = newState.displayedLoads.map(load => {
        return { ...load, isChecked };
      });

      const selectedLoads = isChecked ? [...displayedLoads] : [];

      return { ...newState, displayedLoads, selectedLoads };
    }
    case AdvancedLoadsConstants.SET_SUMMARY:
      return { ...newState, summary: action.payload };
    case AdvancedLoadsConstants.SET_SELECTED_LOADS:
      return { ...newState, selectedLoads: action.payload };
    case AdvancedLoadsConstants.SET_STATUS_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, statusFilters: action.payload } };
    case AdvancedLoadsConstants.SET_SORT_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload } };
    case AdvancedLoadsConstants.SET_DATE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, ...action.payload } };
    case AdvancedLoadsConstants.SET_TEXT_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, filterText: action.payload } };
    case AdvancedLoadsConstants.SET_PAGE_FILTER:
      return { ...newState, searchFilters: { ...newState.searchFilters, pageNumber: action.payload } };
    case AdvancedLoadsConstants.SET_DISPLAY_LOADING_INDICATOR:
      return { ...newState, displayLoadingIndicator: action.payload };
    default:
      return state;
  }
}
